import { StrictMode, Suspense, lazy } from "react";
// import ReactDOM from "react-dom/client";
import ReactDom from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./i18n";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
const App = lazy(() => import("./App"));

const rootElement = document.getElementById("root");

if (rootElement && rootElement!.hasChildNodes()) {
  ReactDom.hydrateRoot(
    rootElement,
    <StrictMode>
      <Suspense fallback="loading">
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Suspense>
    </StrictMode>
  );
  // hydrate(
  //   <React.StrictMode>
  //     <React.Suspense fallback="loading">
  //       <BrowserRouter>
  //         <App />
  //       </BrowserRouter>
  //     </React.Suspense>
  //   </React.StrictMode>,
  //   rootElement
  // );
} else {
  ReactDom.createRoot(rootElement as HTMLElement).render(
    <StrictMode>
      <Suspense fallback="loading">
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Suspense>
    </StrictMode>
  );
}

// const root = ReactDOM.createRoot(
//   document.getElementById("root") as HTMLElement
// );
// root.render(
//   <React.StrictMode>
//     <React.Suspense fallback="loading">
//       <BrowserRouter>
//         <App />
//       </BrowserRouter>
//     </React.Suspense>
//   </React.StrictMode>
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
